<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">User Profile</span>
      </v-card-title>
      <v-card-text>
        <v-container v-if="userProfile.id">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="First name"
                required
                v-model="userProfile.firstname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Last name"
                required
                v-model="userProfile.lastname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Email"
                required
                v-model="userProfile.email"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Phone"
                v-model="userProfile.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="userProfile.sex"
                :items="gender"
                label="Gender"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="userProfile.birthdate"
                    label="Date of birth"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="userProfile.birthdate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Address 1"
                v-model="userProfile.address1"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Address 2"
                v-model="userProfile.address2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="City"
                v-model="userProfile.city"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="State"
                v-model="userProfile.state"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Postcode"
                v-model="userProfile.postcode"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Country"
                v-model="userProfile.country"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog"
          >Close</v-btn
        >
        <v-btn color="blue darken-1" text @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dialogMixin from "@/mixins/dialogMixin"

export default {
  data() {
    return {
      //showDialog: false,
      menu2: false,
      gender: [
        { text: "Male", value: "M" },
        { text: "Female", value: "F" },
        { text: "Other", value: "O" },
      ],
      defaultData: {},
      userProfile: {}
    };
  },
  // created() {
  //   this.showDialog = true
  // },
  async mounted() {
    let user_profile = this.$store.state.user_profile
    if(user_profile.id){
      this.userProfile = user_profile
      //this.$store.commit('SHOW_DIALOG', 'user_profile') //this.showDialog = true
    } else {
      try {
        let res = await this.$store.dispatch("fetchUserProfile")
        this.userProfile = res
      } catch (error) {
        console.log("error", error);
      }
    }
    this.showDialog()
  },
  methods: {
    save() {
      this.$store.dispatch({
        type: 'saveProfile',
        profile: this.userProfile
      }).then(() => {
        this.closeDialog()
      });
    },
    close() {
      this.closeDialog()
    }
  },
  mixins: [dialogMixin]
};
</script>
